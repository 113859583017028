import React from 'react'
import { GoogleMap, Marker} from '@react-google-maps/api';


const containerStyle = {
  width: '100%',
  height: '300px'
};

function Map({location}) {
  
  return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center= {location}
        zoom={19} 
        mapTypeId={'satellite'}
      >
         <Marker
          position={location}
        /> 
      </GoogleMap>
  )
}

export default Map
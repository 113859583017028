export const ServicesData = [

{
    id: 1,
    name: "lawn mowing",
    description: "Flat Priced No nonsense Mowing Service",
    price: "$45",
    image: require("./imgs/mowing.jpg"),
},

{
    id: 2,
    name: "Trimming",
    description: "Perimeter Trimming of your property / hardscape/ sidewalks",
    price: "$11",
    image: require("./imgs/trimming.jpg"),
},

{
    id: 3,
    name: "Fencing",
    description: "New straight fencing installed to proper depth evertime!",
    price: "$35 panel",
    image: require("./imgs/images.jpg"),
},

]

export const Addons = [

    {
        id: 1, 
        name: "Drought Monitoring",
        description: "",
        price: ""

    },

    {
        id: 2, 
        name: "trimming",
        description: "complete perimeter and hardscape trimming",
        price: "$7"

    },
    {
        id: 1, 
        name: "Drought Monitoring",
        description: "",
        price: ""

    }


]
  //AIzaSyBGbJqGtlYQxG-4xUEportA5xQzBw2qbKc 
import React, {createContext,useContext, useEffect, useState} from "react";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
import { AbortController } from "node-abort-controller";
import Map from './Map'
import { TextField, Container, Button, Typography, Checkbox,  } from "@mui/material";
//import {FavoriteBorder, Favorite}  from '@mui/icons-material/FavoriteBorder';
import './form.css'

const center = {
   lat:41.491632,
   lng:-78.677658 
 };

export const LocationContext = createContext(center)

export const useLocation = () => useContext(LocationContext);

  const Searchbar = ({selected}) => {

    console.log("selected",{selected})
    const [form, setForm] = useState({
      firstname: "",
      lastname: "",
      email: "",
      address: "",
      parcelsize: ""
    })
    const [address, setAddress] = useState("");
    const [location, setLocation] = useState(null);
    const [acres, setAcres] = useState(null);

    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        /* Define search scope here */
      },
      debounce: 300,
    });
/*
const getParcelData = (setAcres) => {
  // in future this will animate while request is loading
  console.log("searching parcel data...")
   fetch(`/property/${address}`)
  .then(response => response.json(data))
  .then(data => setAcres({Acres: `${data}`}))
  console.log("Lot size:",data)
}
*/  useEffect(() => {
  const getParcelData = () => {
    // in future this will animate while request is loading
    console.log("searching parcel data...");
    fetch(`/property/${address}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Lot size:", data);
        setAcres({ Acres: Math.round(data) });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (address !== "") {
    getParcelData();
  }
}, [address]);

    const handleInput = (e) => {
      // Update the keyword of the input element
      setValue(e.target.value);
     
    };

    const findperimeter = (acres) => {

      const area = acres; // given area in square feet
      const pi = Math.PI; // get the value of pi from the Math object

// calculate the radius using the given area
const radius = Math.sqrt(area/pi);

// calculate the circumference using the radius and pi
const perimeter = Math.round(2 * pi * radius);

return perimeter;

    }
      
  
    const handleSelect =
      ({ description }) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        setAddress(description);
        clearSuggestions();
        
        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            console.log("📍 Coordinates: ", { lat, lng });

var newcenter = {
  lat, lng
}
            setLocation(newcenter)
            // getJsonFiles(address)
          //  setLocation(, false)
          LocationContext.location = location;
            console.log("state:", lat , lng);
        })
          .catch((error) => {
            console.log("😱 Error: ", error);
          });
      };

//map through possible suggested addresses and render them in a dropdown
    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
  
        return (
            <div id="dropdown">
          <p key={place_id} onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </p>
          </div>
        );
      });
  
      //const searchfieldString = `Where are we ${selected}`

    return (
        
        <>
         <LocationContext.Provider value={location}>
        <div>

        {location ? <Map location={location} /> : 
        <>

        <Typography id="maintext"> The Future of Lawn Care</Typography>
        <Typography id="subtext">Customized lawn care once or with a monthly subscription</Typography>
        <div id="mainimage"/>
        </>
        
        
        }
             
             
        </div>
        
        <Container maxWidth="sm">
        
        {/* <div ref={ref}> */}<div id="forminput">
                <TextField
                    id="fullWidth"
                    helperText="enter your address to load custom rates"
                    value={value}
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder="Enter Address for Quote"
                     />
                {/* We can use the "status" to decide whether we should display the dropdown or not */}
                {status === "OK" && <ul>{renderSuggestions()}</ul>}
                {/* <Button onClick={handleInput}>Add</Button> */}
            </div>
            {address.length > 0 &&  
            <>
            
            <div id="customerform">
            <Typography
                id="fullWidth"
                value={address} /><TextField
                  id="fullWidth"
                  label="first name" /><TextField
                  id="fullWidth"
                  label="last name" /><TextField
                  id="fullWidth"
                  label="email" /><TextField
                  id="fullWidth"
                  label="phone #" /><Typography>
           

                 

                </Typography>
                </div>
                <div id="result">
                  {acres ? <span>
                  
                  <div>
                  We've calculated this yard is about {acres.Acres} sqft.
                  </div>
                  <div>
                  That's about {findperimeter(acres.Acres)} feet of perimeter.
                  </div>
                  </span>
                   
                   : null}
                 

                 
                  </div>
                 
                
                <div id="sub">
                <Typography>Would you like this service every month? Cancel at anytime.</Typography>
                <Checkbox label="Schedule monthly?"/>

                </div>
                
                
                <Button variant="contained" >Fast Quote</Button>
                </>
                
            }
            </Container>
            </LocationContext.Provider>
            </>


    );
  
  };
  export default Searchbar
import * as React from "react";
import { useState } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import './App.css';
import SearchBar from "./form";
import Dashboard from "./dashboard";
import Login from "./login";
import {ServicesData} from './services.js'
import HomeIcon from '@mui/icons-material/Home';
import GrassIcon from '@mui/icons-material/Grass';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginIcon from '@mui/icons-material/Login';
         
import { Box, 
         Button, 
         CardActions, 
         Typography, 
         CardActionArea , 
         Card , 
         CardContent , 
         CardMedia, 
         BottomNavigation, 
         BottomNavigationAction,
         Paper 
        } from '@mui/material';

export default function App({selected}) {
  return (  
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<Home />} />
          <Route path="Mow" element={<SearchBar  selected={selected}/>}/>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
  );
}
function Layout({selected}) {

    const [value, setValue] = useState(0);

  return (
    <div>    
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:999, backgroundColor: '#7e57c2'}} elevation={5} >
      <BottomNavigation
      position="fixed"
      showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{ backgroundColor: '#7e57c2' }}
      >
      
           <Link to="/">
           <div id="logo"></div>
          <BottomNavigationAction label="Home" />
          
            </Link>
            <Link to={{pathname:"/mow",
                      state: {
                        selected: {selected}
                      }
                      
            }} >
          <BottomNavigationAction label="Mow" icon={<GrassIcon style={{color: 'white'}}/>} />
            </Link>
            <Link to="/dashboard">
          <BottomNavigationAction label="Dashboard" icon={<SettingsIcon style={{color: 'white'}}/>}/>
          </Link>
          <Link to="/login">
          <BottomNavigationAction label="Login" icon={<LoginIcon style={{color: 'white'}}/>}/>
          </Link>
          </BottomNavigation>
          </Paper>
      <Outlet /> 
    </div>
  );
}

function Home() {



  const[selected, setSelected] = useState("");

  const selectAction = (e) => {
    setSelected(e.target.value)
    console.log(e.target.value)

  }



    const listItems = ServicesData.map((d) => 
    <Box sx={{ display: 'flex', flex: 3, justifyContent: 'center', margin: '5% 0px'}}   key={d.id}>
    <Card sx={{ maxWidth: 300 }}>
<CardContent>
<CardActionArea>
  <CardMedia
    component="img"
    image={d.image}
    alt="CardMedia Image Example"
    height="140"
    title="CardMedia Image Example"
  
  />
</CardActionArea>
   
    <Typography>
    <div key={d.name}>{d.name}</div>
    </Typography>
    <Typography>
    <div key={d.price}>{d.price}</div>
    </Typography>
    <Typography>
    <div key={d.description}>{d.description}</div>
    </Typography>
    
    <CardActions>
        <Button value={d.name} onClick={selectAction}>Learn More</Button>
    </CardActions>
    </CardContent>
    </Card>
    </Box>
    
    );

  return (
    <div>
 <Box justifyContent="center" alignItems="center">
 <Typography style={{color: 'gray', fontSize: '20px',display: 'flex', justifyContent: 'center', margin: '30px 0'}}> Check out our Local Services </Typography>
   
    {/* {selected ? <Redirect to="/mow" />:    } */}
    {listItems}
    </Box>
    </div>
     
  );
}

function NoMatch() {
  return (
    <div>
      <h2>404 Not Found</h2>
      
        <Link to="/">Go to the home page</Link>
      
    </div>
  );
}


const userData = {
        uid: 1,
        name:"Doug",
        address:"38 Powers Ave. Johnsonburg, Pa 15845",
        phone: "18143354697",
        orders: [
                {
                id: 1,
                amount: "$76",
                date: ""
    
            },
                {
                id: 2,
                amount: "$76",
                date: ""
                }
        
        ]
    };
export {userData};
import React from "react";
import { ServicesData } from "./services";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { Card } from "@mui/material";
import { CardActionArea } from "@mui/material";
import { CardContent } from "@mui/material";
import { CardMedia } from "@mui/material";
import { CardActions } from "@mui/material";
import { Typography } from "@mui/material";


function CreateJob () {
    
    const listServices = ServicesData.map((d) => 
    <Box sx={{ justifyContent: 'center' }}   key={d.id}>
    <Card sx={{ maxWidth: '50%'}}>
<CardContent>
<CardActionArea>
<>
  <CardMedia
    component="img"
    image={d.image}
    alt="CardMedia Image Example"
    height="50"
    title="CardMedia Image Example"
  
  />
  <Button>upload</Button>
  </>
</CardActionArea>
   
<Typography>
    <div key={d.name}>{d.name}</div>
    </Typography>
    <Typography>
    <div key={d.price}>{d.price}</div>
    </Typography>
    <Typography>
    <div key={d.description}>{d.description}</div>
    </Typography>
    </CardContent>
    <CardActions>
        <Button>delete</Button>
    </CardActions>
    </Card>
    </Box>

    )








    return(
        
<>
<>
    <h2>My Services</h2>
    <Button onClick={()=>{}} variant="contained">Add</Button>

    </>
   <div>
   {listServices}
   </div>
    

    </>



    )
}

export default CreateJob;
import { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './form.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('/login', { email, password });
      localStorage.setItem('token', response.data.token);
      // Redirect to dashboard page
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (

    <div className="form-container">
      <Card>
        <CardContent>
          <h2>Login</h2>
          {error && <p>{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label htmlFor="email-input"></label>
              <TextField id="email-input" type="email" variant="outlined" value={email} helpertext="email" placeholder="email" onChange={handleEmailChange} />
            </div>
            <div className="input-container">
              <label htmlFor="password-input"></label>
              <TextField id="password-input" type="password" variant="outlined" value={password} placeholder="password" onChange={handlePasswordChange} />
            </div>
            <Button type="submit" variant="contained" color="primary" className="submit-button">Login</Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};











export default Login;




